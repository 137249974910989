import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BlogItem from "./BlogItem"
import BookItem from "./BookItem";
import Newsletter from "../Newsletter";

const Blog = () => {

    const articles = useStaticQuery(graphql`
    {
        allMarkdownRemark {
        nodes {
            frontmatter {
                title
                tag
                slug
                excerpt
                link
                price
                pages
                claim
                features
                language
                image {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                }
            }
            html
            }
        }
    }
    `)

    const allBooks = articles.allMarkdownRemark.nodes.map((item, index) => (
        <BookItem
            key={index}
            slug={item.frontmatter.slug}
            alt={item.frontmatter.title}
            title={item.frontmatter.title}
            tag={item.frontmatter.tag}
            link={item.frontmatter.link}
            excerpt={item.frontmatter.excerpt}
            image={item.frontmatter.image}
            price={item.frontmatter.price}
            claim={item.frontmatter.claim}
            features={item.frontmatter.features}
            pages={item.frontmatter.pages}
            language={item.frontmatter.language}
        />
    ))

    const allArticles = articles.allMarkdownRemark.nodes.map((item, index) => (
        <BlogItem
        key={index}
        slug={item.frontmatter.slug}
        alt={item.frontmatter.title}
        title={item.frontmatter.title}
        tag={item.frontmatter.tag}
        link={item.frontmatter.link}
        excerpt={item.frontmatter.excerpt}
        />
    ))
    return (
        <div className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0 flex flex-row flex-wrap">
            <div className="w-full md:w-content">
                <div className="w-full py-10 md:pr-16 lg:pr-24">
                    {allArticles}
                    <Newsletter></Newsletter>
                </div>
            </div>
            <aside className="w-full md:w-sidebar pt-12">
                <h2 className="font-bold text-gray-800 text-2xl">Our books</h2>
                <div className="mt-8">
                    {allBooks}
                </div>
            </aside>
        </div>
    )
}

export default Blog
