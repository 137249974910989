import React from "react"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Blog from "../components/blog"
import {SEO} from "../components/Seo";
import {Helmet} from "react-helmet";

const Home = () => {
  return(
    <Layout>
    <Helmet>
        <title>Globale Trade Services | SAP-Knowledge like an espresso (books & e-books)</title>
    </Helmet>
      <Header/>
      <Blog/>
    </Layout>
  )
}

export default Home

export const Head = () => (
    <SEO/>
)
